<template>
  <v-container fluid>
      <!-- <global-filterMenuNew disableTerminals /> -->
    <v-row>
      <global-EventFilterMenuProductSales header='Sales'/>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Total Transactions
          </v-card-title>
          <v-card-text >
            <v-row>
              <v-col lg="6" md="12" sm="6" cols="12">
                <v-skeleton-loader
                  elevation="2"
                  type="sentences@6"
                  :loading="productSalesTotalTransactionsLoading"
                >
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Total Transactions: </div>
                    <!-- 76714 -->
                    {{productSalesTotalTransactions.total_sales_data.TotalTransactions  | currency}}
                  </v-card-text>
                </v-card>

                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Cash: </div>
                    <!-- 2159 -->
                    {{productSalesTotalTransactions.total_sales_data.TotalTransactionsCash | currency}}
                    
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Card: </div>
                    <!-- 74262 -->
                    {{productSalesTotalTransactions.total_sales_data.TotalTransactionsCard | currency}}
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Voucher: </div>
                    <!-- 293 -->
                    {{productSalesTotalTransactions.total_sales_data.TotalTransactionsVoucher | currency}}
                  </v-card-text>
                </v-card>
                
                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Total Products: </div>
                    <!-- 151132 -->
                    {{productSalesTotalTransactions.total_sales_data.Quantity | currency}}
                  </v-card-text>
                </v-card>

                <v-card
                  class="primary mb-2"
                  dark
                >
                  <v-card-text class="headline font-weight-bold py-0" style="border: 0px solid red">
                    <div class="subtitle-1"> Products Per head </div>
                    <!-- 1.61 -->
                    {{productSalesTotalTransactions.total_sales_data.Productsperhead}}
                  </v-card-text>
                </v-card>
                </v-skeleton-loader>
                
              </v-col>
              <v-col lg="6" md="12" sm="6" cols="12">
                <skeletons-graph :loading="productSalesTotalTransactionsLoading">
                  <charts-pie :chartdata="totalTransactionsChart" :options="options" />
                </skeletons-graph>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-pie </v-icon>
            Stall Comparison
          </v-card-title>
          <v-card-text v-if="stallComparisonDonutChart.datasets[0].data.length > 0">
            <skeletons-graph :loading="productSalesTotalTransactionsLoading">
              <charts-pie :chartdata="stallComparisonDonutChart" :options="options" />
            </skeletons-graph>
          </v-card-text>
          <v-card-text v-else>
            <label>No data available</label>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-chart-line </v-icon>
            Total Transactions Timeline - Coming soon!
          </v-card-title>
          <v-card-text>
            <skeletons-graph :loading="productSalesTotalTransactionsLoading">
              <charts-line :chartdata="totalSalesandStallComparisonTimelineChart" :options="options" />
            </skeletons-graph>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
    
    <v-row>        
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Product Sales Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headers"
              :items="formattedProductTableData"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-file-table </v-icon>
            Product Tags Table
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selectedTransaction"
              :headers="headersOfTagTable"
              :items="productSalesTotalTransactions.products_table_data"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    
    selectedTransaction: {},
    headers: [
      { text: "Rank", value: "Rank"},
      { text: "Product Name", value: "Product Name"},
      { text: "Quantity", value: "Quantity"},
      { text: "Net Sales", value: "Net Sales"},
      { text: "Gross Sales", value: "Gross Sales"}
    ],

    headersOfTagTable: [
      { text: "Rank", value: "Rank"},
      { text: "Tag name", value: "Product Name"},
      { text: "Quantity", value: "Quantity"},
      { text: "Net Sales", value: "Net Sales"},
      { text: "Gross Sales", value: "Gross Sales"}
    ],

    chartData: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Prices',
          borderColor: '#249EBF',
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          backgroundColor: 'transparent',
          data: [5, 10, 15, 25, 45, 70, 115, 185, 70, 75, 70, 60]
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        labels: {
            fontColor: "white"
        }
      },
      tooltips: {
        callbacks: {
            label(tooltipItem, data) {
              const totalValue = data.datasets[0].data.reduce((acc, value) => acc + parseInt(value), 0);
              const currentValue = parseInt(data.datasets[0].data[tooltipItem.index])
              const percentage = (currentValue/totalValue * 100).toFixed(2)
              return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]} (${percentage})%`;
            }
        }
      }
    },
    pieChartData: {
      labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 10,
        hoverBorderWidth: 5
      }],
    },

    // loading
    timelineLoading: true,
  }),

  created() {
    
    console.log("HERE:     productSalesTotalTransactions.products_table_data")
    console.log(this.productSalesTotalTransactions.products_table_data)

    var self = this
      setTimeout(
        function() {
          self.timelineLoading = false
        }, 2000);
  },
  filters: {
    currency(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      return '$' + formatter.format(value);
    },
    number(value) {
      var formatter = new Intl.NumberFormat('en-AU',{
        maximumFractionDigits: 0
      })
      return formatter.format(value);
    }
  },
  computed: {
    ...mapState('dashboard', ['productSalesTotalTransactions', 'productSalesTotalTransactionsLoading']),
    
    stallComparisonDonutChart () {
      return {
        labels: this.productSalesTotalTransactions.stall_comparison_data.map(x => x['Stall Name']),//['Cash', 'Card', 'Voucher'],
        datasets: [
          {
            backgroundColor: this.$generateColors.getColors(this.productSalesTotalTransactions.stall_comparison_data.length),//['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            data: this.productSalesTotalTransactions.stall_comparison_data.map(x => x.Sales),//[this.takingsTotalSales.cash, this.takingsTotalSales.card, this.takingsTotalSales.voucher],
            hoverBorderWidth: 3
          }
        ]
      }
    },
    totalTransactionsChart () {
      return {
        labels: ['Cash', 'Card', 'Voucher'],
        datasets: [
          {
            backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            data: [this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCash, this.productSalesTotalTransactions.total_sales_data.TotalTransactionsCard, this.productSalesTotalTransactions.total_sales_data.TotalTransactionsVoucher],
            hoverBorderWidth: 3
          }
        ]
      }
    },
    totalSalesandStallComparisonTimelineChart () {
      return {
        labels: this.productSalesTotalTransactions.stall_comparison_timeline[0].Stalls.map(obj=> {
                  return obj.Timestamp
                }),
        datasets: this.productSalesTotalTransactions.stall_comparison_timeline.map((obj, index) => {
          obj.label = obj['Stall Name']
          obj.borderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
          obj.pointBackgroundColor = 'white',
          obj.borderWidth = 1,
          obj.pointBorderColor = index === 0 ? '#FF0E00' : index === 1 ? '#649EB9' : 'blue',
          obj.backgroundColor = 'transparent',
          obj.data = obj.Stalls.map(obj=> {
                      return obj.SalesAmount
                     })
          return obj;
        })
      }
    },
    formattedProductTableData() {
      if (!this.productSalesTotalTransactions || !this.productSalesTotalTransactions.products_table_data) {
        return [];
      }

      return this.productSalesTotalTransactions.products_table_data.map(item => ({
        'Rank': item['Rank'],
        'Product Name': item['Product Name'],
        'Quantity': item['Quantity'] !== undefined ? this.$options.filters.number(item['Quantity']) : 'N/A',
        'Net Sales': item['Net Sales'] !== undefined ? this.$options.filters.currency(item['Net Sales']) : 'N/A',
        'Gross Sales': item['Gross Sales'] !== undefined ? this.$options.filters.currency(item['Gross Sales']) : 'N/A'
      }));
    }
  }
}
</script>


<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>